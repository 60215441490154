var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"orderSummaryInfos"},[_c('div',{staticClass:"row q-col-gutter-md"},[_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-4 o-1"},[_c('ul',{staticClass:"orderSummaryITotal"},[_c('li',[_vm._v("\n            Sub Total\n            "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.currency(_vm.orderDetail.subTotal))}})]),(
              _vm.orderDetail.codeName == 'sd' &&
              _vm.orderDetail.shippingRate !== null
            )?[_c('li',[_vm._v("\n              Shipping Fee\n              "),_c('span',[_vm._v("\n                "+_vm._s(_vm.orderDetail.shippingRate == 0
                    ? 'Free'
                    : _vm.$options.filters.currency(
                        _vm.orderDetail.shippingRate,
                        false,
                        true
                      ))+"\n              ")])])]:_vm._e(),(_vm.orderDetail?.tipAmount)?[_c('li',[_vm._v("\n              Tip\n              "),_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.orderDetail?.tipAmount,false, true)))])])]:_vm._e(),(
              _vm.orderDetail.additionalCharges &&
              _vm.orderDetail.additionalCharges.length
            )?_vm._l((_vm.orderDetail.additionalCharges),function(additionalCharge){return _c('li',{key:additionalCharge.chargeName},[_vm._v("\n              "+_vm._s(additionalCharge.chargeName)+"\n              "),_c('span',[_vm._v(_vm._s(_vm._f("currency")(additionalCharge.chargeAmount,false, true)))])])}):_vm._e(),(_vm.orderDetail.discount)?[_c('li',[_vm._v("\n              Discount Applied\n              "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.currency(_vm.orderDetail.discount, true))}})])]:_vm._e(),(_vm.orderDetail.buydownAmount)?[_c('li',[_vm._v("\n              Other Discount\n              "),_c('span',[_vm._v("- "+_vm._s(_vm._f("currency")(_vm.orderDetail.buydownAmount,false)))])])]:_vm._e(),(_vm.orderDetail.appliedLnsDollars)?_c('li',[_vm._v("\n            Loyalty Dollars Applied\n            "),_c('span',[_vm._v("\n              -\n              "+_vm._s(_vm._f("currency")(_vm.orderDetail.appliedLnsDollars))+"\n            ")])]):_vm._e(),(_vm.orderDetail.otherTax > 0)?[_c('li',[_vm._v("\n              Tax\n              "),_c('span',{domProps:{"innerHTML":_vm._s(
                  _vm.$options.filters.currency(_vm.orderDetail.otherTax, false, true)
                )}})])]:_vm._e(),(_vm.orderDetail.occupationalTax > 0)?[_c('li',[_vm._v("\n              Occupational Tax\n              "),_c('span',{domProps:{"innerHTML":_vm._s(
                  _vm.$options.filters.currency(
                    _vm.orderDetail.occupationalTax,
                    false,
                    true
                  )
                )}})])]:_vm._e(),(
              _vm.orderDetail &&
              _vm.orderDetail.codeName &&
              _vm.orderDetail.codeName == 'scd' &&
              _vm.orderDetail.shippingRate > 0
            )?[_c('li',[_vm._v("\n              Delivery Fee\n              "),_c('span',{domProps:{"innerHTML":_vm._s(
                  _vm.$options.filters.currency(
                    _vm.orderDetail.shippingRate,
                    false,
                    true
                  )
                )}})])]:_vm._e(),(
              _vm.orderDetail.appliedLnsCoupons &&
              _vm.orderDetail.appliedLnsCoupons.length
            )?[_vm._m(0),_c('q-separator'),_vm._l((_vm.orderDetail.appliedLnsCoupons),function(appliedLnsCoupon){return _c('li',{key:appliedLnsCoupon.id,staticStyle:{"padding-right":"0px !important"}},[_c('span',[_vm._v(" - "+_vm._s(_vm._f("currency")(appliedLnsCoupon.amount))+" ")]),_c('div',[_vm._v("\n                "+_vm._s(_vm.appliedCouponName(appliedLnsCoupon.name))+"\n                "),_c('q-tooltip',{attrs:{"max-width":"180px","content-class":"bg-dark"}},[_vm._v("\n                  "+_vm._s(appliedLnsCoupon.name)+"\n                ")])],1)])})]:_vm._e(),(
              _vm.orderDetail.appliedDiscountCoupons &&
              _vm.orderDetail.appliedDiscountCoupons.length
            )?[_vm._m(1),_c('q-separator'),_vm._l((_vm.orderDetail.appliedDiscountCoupons),function(appliedDiscountCoupon){return _c('li',{key:appliedDiscountCoupon.id,staticStyle:{"padding-right":"0px !important"}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(appliedDiscountCoupon.amount))+" ")]),_c('div',[_vm._v("\n                "+_vm._s(_vm.appliedCouponName(appliedDiscountCoupon.couponCode))+"\n                "),_c('q-tooltip',{attrs:{"max-width":"180px","content-class":"bg-dark"}},[_vm._v("\n                  "+_vm._s(appliedDiscountCoupon.couponCode)+"\n                ")])],1)])})]:_vm._e(),_c('q-separator',{staticClass:"q-mt-sm"}),_c('li',{staticClass:"total"},[_vm._v("\n            Order Total\n            "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.currency(_vm.orderDetail.finalPayAmount))}})])],2)]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-5 o-3"},[(_vm.orderDetail && _vm.orderDetail.deliveryInstruction)?[_c('h5',[_vm._v("Order Instruction")]),_c('p',{staticClass:"text-justify text-caption word-break-word"},[_vm._v("\n            "+_vm._s(_vm.orderDetail.deliveryInstruction)+"\n          ")])]:_vm._e()],2),(_vm.orderDetail.isPayAtStore || _vm.orderDetail.orderPaymentDetail)?_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-3 o-2"},[_c('h5',[_vm._v("Payment Method")]),(_vm.orderDetail.isPayAtStore)?_c('p',[_c('SvgIcon',{staticClass:"q-mr-sm",attrs:{"size":"44px","icon":"pay-at-store"}}),_vm._v("\n          Pay at store\n        ")],1):(_vm.orderDetail.orderPaymentDetail)?_c('p',[_c('span',{staticClass:"text-weight-bold"},[_vm._v(" Credit or debit card")]),_c('br'),_c('span',{class:`paymentIcon paymentIcon-left`},[_c('img',{attrs:{"src":`images/payment/${
                _vm.getCardType(_vm.orderDetail.orderPaymentDetail.cardType).logo
              }`,"title":_vm.getCardType(_vm.orderDetail.orderPaymentDetail.cardType).title}})]),_vm._v("\n\n          "+_vm._s(_vm.orderDetail.orderPaymentDetail.cardHolderName)+"\n          "),(_vm.orderDetail.orderPaymentDetail.isDefault)?_c('span',{staticClass:"default q-ml-sm"},[_vm._v("\n            Default ")]):_vm._e(),_c('br'),_vm._v("\n          xxxx-xxxx-xxxx-"+_vm._s(_vm.orderDetail.orderPaymentDetail.cardLast4Digits)+"\n          "),_c('br'),_vm._v("\n          Expires :\n          "+_vm._s(_vm.orderDetail.orderPaymentDetail.cardExpiry)+"\n        ")]):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('p',{staticClass:"q-mt-sm applied-coupons"},[_vm._v("Applied Loyalty Coupon(s)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('p',{staticClass:"q-mt-sm applied-coupons"},[_vm._v("Applied Discount Coupons")])])
}]

export { render, staticRenderFns }