<template>
  <div class="col-12 col-md-12">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-4">
        <div class="orderItemDeliveryOptXs">
          <div class="row items-start">
            <div class="col-6 col-md-6">
              <div class="orderItemDeliveryOpt">
                <svgIcon color="primaryOnBody" :icon="orderDetail.codeName" />
                <h5>
                  {{ orderDetail.displayName }}
                </h5>
                <div
                  v-if="
                    orderDetail.codeName === 'scd' ||
                    orderDetail.codeName === 'sd'
                  "
                  class="text-weight-bold text-body2 q-mt-sm"
                  >{{
                    orderDetail.codeName == 'scd' ? 'Deliver To' : 'Ship To'
                  }}
                </div>
                <template
                  v-if="
                    orderDetail.codeName === 'scd' ||
                    orderDetail.codeName === 'sd'
                  "
                >
                  <p
                    class="text-weight-bold text-body2"
                    v-if="orderDetail.shippingAddress"
                  >
                    {{
                      orderDetail.shippingAddress.firstName +
                      ' ' +
                      orderDetail.shippingAddress.lastName
                    }}
                  </p>
                  <p
                    v-html="
                      $options.filters.formatAddress(
                        orderDetail.shippingAddress
                      )
                    "
                  />
                </template>
                <template v-else>
                  <p>{{
                    orderDetail.pickupStore && orderDetail.pickupStore.name
                  }}</p>
                  <p
                    v-html="
                      $options.filters.formatAddress(orderDetail.pickupStore)
                    "
                  />
                </template>
              </div>
            </div>
            <div
              class="col-6 col-md-6"
              v-if="
                (orderDetail.pickupType && orderDetail.codeName == 'sp') ||
                orderDetail.timeSlot
              "
            >
              <div
                v-if="orderDetail.pickupType && orderDetail.codeName == 'sp'"
              >
                <p class="orderpickmethod">
                  Pickup Method :
                  <span class="text-primary text-weight-bold">
                    {{
                      orderDetail.pickupType == 1 ? 'In Store' : 'Curbside'
                    }}</span
                  ></p
                ></div
              >
              <div v-if="orderDetail.timeSlot">
                <div class="orderPickDesc">
                  <div class="orderPickDescTitle">Reserved Time Slot</div>
                  <div class="orderPickDescDate">
                    {{ orderDetail.timeSlot.bookingDate | formatDate }}
                  </div>
                  <div class="orderPickDescTime">
                    {{ orderDetail.timeSlot.slotTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-8">
        <div class="orderSteps" v-if="!isGuestOrderCancelled">
          <ul>
            <li
              v-for="(tracking, index) of orderDetail.orderTrackings"
              :key="index"
              :class="[
                tracking.isReached
                  ? 'completed'
                  : !orderDetail.orderTrackings[index - 1] ||
                    orderDetail.orderTrackings[index - 1].isReached
                  ? 'current'
                  : '',
              ]"
            >
              <span class="dot"></span>
              <span class="text">{{ tracking.title }}</span>
            </li>
          </ul>
        </div>
        <div class="contactInfo">
          <ul class="contactInfoU">
            <template v-if="orderDetail.codeName == 'sd'">
              <li
                v-if="!orderDetail.trackingNumber || !orderDetail.trackingUrl"
              >
                <span class="text-weight-bold"
                  >Order tracking details will be shown here once the order is
                  shipped</span
                >
              </li>
              <template v-else>
                <li>
                  <span>Tracking Number</span>
                  {{ orderDetail.trackingNumber }}
                </li>
                <li>
                  <span>Shipped with: {{ orderDetail.shippingCarrier }}</span>
                  <q-btn
                    unelevated
                    no-caps
                    label="Track Order"
                    class="track-btn text-white"
                    color="primary"
                    @click="openTrackingURL(orderDetail.trackingUrl)"
                  />
                </li>
              </template>
            </template>
            <template
              v-else-if="
                orderDetail.codeName == 'scd' && orderDetail.deliveryMethod == 1
              "
            >
              <li v-if="!isOutofDelivery">
                <span class="text-weight-bold"
                  >Order tracking link will be shown once your order is picked
                  up by the delivery person (Out of Delivery)</span
                >
              </li>
              <template v-else>
                <li>
                  <span
                    class="text-weight-bold text-green"
                    v-if="
                      orderDetail.orderTrackings[
                        orderDetail.orderTrackings.length - 1
                      ].status == 'Delivered' &&
                      orderDetail.orderTrackings[
                        orderDetail.orderTrackings.length - 1
                      ].isReached
                    "
                    >Order was delivered.
                  </span>
                  <span class="text-weight-bold" v-else
                    >Your order is out of delivery and reaching at you soon.
                  </span>
                  <q-btn
                    unelevated
                    no-caps
                    flat
                    color="transparent"
                    class="track-btn text-red"
                    @click="openTrackingURL(orderDetail.trackingUrl)"
                    >Track Your Order <q-icon name="map" color="red"
                  /></q-btn>
                </li>
              </template>
            </template>
            <template
              v-else-if="
                orderDetail.customerDetailsPickupInfo &&
                orderDetail.codeName !== 'sd'
              "
            >
              <li>
                <span>Full Name</span>
                {{ orderDetail.customerDetailsPickupInfo.customerName }}
              </li>
              <li>
                <span>Confirmation Code</span>
                <strong
                  v-html="
                    orderDetail.customerDetailsPickupInfo.confirmationCode
                  "
                />
              </li>
              <!-- v-if="
                  (orderDetail.codeName == 'sp' &&
                    isOrderStatusReached('Ready_For_Pickup')) ||
                  (orderDetail.codeName == 'scd' &&
                    orderDetail.deliveryMethod == 0 &&
                    isOrderStatusReached('Ready_for_Delivery'))
                " -->
              <li
                class="verification-instructions"
                v-if="
                  orderDetail.isAgeRestricted &&
                  businessConfigurations.isStoreAgeRestricted &&
                  businessConfigurations.enableAgeRestrictedFulfillment &&
                  ((orderDetail.codeName == 'sp' &&
                    isOrderStatusReached('Ready_For_Pickup')) ||
                    (orderDetail.codeName == 'scd' &&
                      orderDetail.deliveryMethod == 0 &&
                      isOrderStatusReached('Ready_for_Delivery')))
                "
              >
                <span class="text-red text-justify">
                  The person fulfilling your order will ask for the
                  <strong
                    v-show="businessConfigurations.allowVerificationByDOB"
                  >
                    Date of Birth
                  </strong>
                  <strong
                    v-show="businessConfigurations.allowVerificationByLicense"
                  >
                    <template
                      v-if="businessConfigurations.allowVerificationByDOB"
                    >
                      /
                    </template>
                    Driver's License
                  </strong>
                  <strong v-show="businessConfigurations.allowVerificationByQR">
                    <template
                      v-if="
                        businessConfigurations.allowVerificationByDOB ||
                        businessConfigurations.allowVerificationByLicense
                      "
                    >
                      /
                    </template>
                    Age-Verified QR Code
                  </strong>
                  to verify the age of the person picking up your order, as the
                  order contains
                  <strong>age-restricted</strong> products.
                </span>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OrderDetailTrack',
  props: {
    orderDetail: {
      type: Object,
      default: new Object(),
    },
  },
  computed: {
    isOutofDelivery() {
      let flag = false
      this.orderDetail.orderTrackings.forEach((element) => {
        if (element.isReached && element.status == 'Out_of_Delivery') {
          flag = true
          return
        }
      })
      return flag
    },
    isGuestOrderCancelled() {
      return this.orderDetail.allowCancelOrder && this.orderDetail.isCancelled
    },
  },
  methods: {
    openTrackingURL(url) {
      window.open(url, '_blank')
    },
    isOrderStatusReached(status) {
      let flag = false
      this.orderDetail.orderTrackings.forEach((element, index, arr) => {
        if (
          element.isReached &&
          element.status == status &&
          arr[index + 1]?.isReached === false
        ) {
          flag = true
          return
        }
      })
      return flag
    },
  },
}
</script>
