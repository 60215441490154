<template>
  <q-dialog ref="dialog" :position="isMobile ? 'bottom' : 'standard'">
    <q-card style="width: 100%">
      <q-card-section class="q-pb-none">
        <div class="row">
          <div class="col-12">
            <p>
              Please tap to confirm you are on the way to the store to pick up
              your order. This gives them time to prepare your order for pickup.
            </p>
          </div>

          <div class="col-12">
            <div class="form-action">
              <q-btn
                unelevated
                color="primary"
                label="Confirm"
                class="q-btn-md full-width"
                @click="onOKClick"
              />
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'EmptyCartConfirm',
  props: {
    text: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  methods: {
    // following method is REQUIRED
    // (don't change its name --> "show")
    show() {
      this.$refs.dialog.show()
    },

    // following method is REQUIRED
    // (don't change its name --> "hide")
    hide() {
      this.$refs.dialog.hide()
    },

    onDialogHide() {
      // required to be emitted
      // when QDialog emits "hide" event
      this.$emit('hide')
    },

    onOKClick() {
      // on OK, it is REQUIRED to
      // emit "ok" event (with optional payload)
      // before hiding the QDialog
      this.$emit('ok')
      // or with payload: this.$emit('ok', { ... })

      // then hiding dialog
      this.hide()
    },

    onCancelClick() {
      // we just need to hide dialog
      this.hide()
    },
  },
}
</script>
