var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-4"},[_c('div',{staticClass:"orderItemDeliveryOptXs"},[_c('div',{staticClass:"row items-start"},[_c('div',{staticClass:"col-6 col-md-6"},[_c('div',{staticClass:"orderItemDeliveryOpt"},[_c('svgIcon',{attrs:{"color":"primaryOnBody","icon":_vm.orderDetail.codeName}}),_c('h5',[_vm._v("\n                "+_vm._s(_vm.orderDetail.displayName)+"\n              ")]),(
                  _vm.orderDetail.codeName === 'scd' ||
                  _vm.orderDetail.codeName === 'sd'
                )?_c('div',{staticClass:"text-weight-bold text-body2 q-mt-sm"},[_vm._v(_vm._s(_vm.orderDetail.codeName == 'scd' ? 'Deliver To' : 'Ship To')+"\n              ")]):_vm._e(),(
                  _vm.orderDetail.codeName === 'scd' ||
                  _vm.orderDetail.codeName === 'sd'
                )?[(_vm.orderDetail.shippingAddress)?_c('p',{staticClass:"text-weight-bold text-body2"},[_vm._v("\n                  "+_vm._s(_vm.orderDetail.shippingAddress.firstName +
                    ' ' +
                    _vm.orderDetail.shippingAddress.lastName)+"\n                ")]):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(
                    _vm.$options.filters.formatAddress(
                      _vm.orderDetail.shippingAddress
                    )
                  )}})]:[_c('p',[_vm._v(_vm._s(_vm.orderDetail.pickupStore && _vm.orderDetail.pickupStore.name))]),_c('p',{domProps:{"innerHTML":_vm._s(
                    _vm.$options.filters.formatAddress(_vm.orderDetail.pickupStore)
                  )}})]],2)]),(
              (_vm.orderDetail.pickupType && _vm.orderDetail.codeName == 'sp') ||
              _vm.orderDetail.timeSlot
            )?_c('div',{staticClass:"col-6 col-md-6"},[(_vm.orderDetail.pickupType && _vm.orderDetail.codeName == 'sp')?_c('div',[_c('p',{staticClass:"orderpickmethod"},[_vm._v("\n                Pickup Method :\n                "),_c('span',{staticClass:"text-primary text-weight-bold"},[_vm._v("\n                  "+_vm._s(_vm.orderDetail.pickupType == 1 ? 'In Store' : 'Curbside'))])])]):_vm._e(),(_vm.orderDetail.timeSlot)?_c('div',[_c('div',{staticClass:"orderPickDesc"},[_c('div',{staticClass:"orderPickDescTitle"},[_vm._v("Reserved Time Slot")]),_c('div',{staticClass:"orderPickDescDate"},[_vm._v("\n                  "+_vm._s(_vm._f("formatDate")(_vm.orderDetail.timeSlot.bookingDate))+"\n                ")]),_c('div',{staticClass:"orderPickDescTime"},[_vm._v("\n                  "+_vm._s(_vm.orderDetail.timeSlot.slotTime)+"\n                ")])])]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-sm-12 col-md-8"},[(!_vm.isGuestOrderCancelled)?_c('div',{staticClass:"orderSteps"},[_c('ul',_vm._l((_vm.orderDetail.orderTrackings),function(tracking,index){return _c('li',{key:index,class:[
              tracking.isReached
                ? 'completed'
                : !_vm.orderDetail.orderTrackings[index - 1] ||
                  _vm.orderDetail.orderTrackings[index - 1].isReached
                ? 'current'
                : '',
            ]},[_c('span',{staticClass:"dot"}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(tracking.title))])])}),0)]):_vm._e(),_c('div',{staticClass:"contactInfo"},[_c('ul',{staticClass:"contactInfoU"},[(_vm.orderDetail.codeName == 'sd')?[(!_vm.orderDetail.trackingNumber || !_vm.orderDetail.trackingUrl)?_c('li',[_c('span',{staticClass:"text-weight-bold"},[_vm._v("Order tracking details will be shown here once the order is\n                shipped")])]):[_c('li',[_c('span',[_vm._v("Tracking Number")]),_vm._v("\n                "+_vm._s(_vm.orderDetail.trackingNumber)+"\n              ")]),_c('li',[_c('span',[_vm._v("Shipped with: "+_vm._s(_vm.orderDetail.shippingCarrier))]),_c('q-btn',{staticClass:"track-btn text-white",attrs:{"unelevated":"","no-caps":"","label":"Track Order","color":"primary"},on:{"click":function($event){return _vm.openTrackingURL(_vm.orderDetail.trackingUrl)}}})],1)]]:(
              _vm.orderDetail.codeName == 'scd' && _vm.orderDetail.deliveryMethod == 1
            )?[(!_vm.isOutofDelivery)?_c('li',[_c('span',{staticClass:"text-weight-bold"},[_vm._v("Order tracking link will be shown once your order is picked\n                up by the delivery person (Out of Delivery)")])]):[_c('li',[(
                    _vm.orderDetail.orderTrackings[
                      _vm.orderDetail.orderTrackings.length - 1
                    ].status == 'Delivered' &&
                    _vm.orderDetail.orderTrackings[
                      _vm.orderDetail.orderTrackings.length - 1
                    ].isReached
                  )?_c('span',{staticClass:"text-weight-bold text-green"},[_vm._v("Order was delivered.\n                ")]):_c('span',{staticClass:"text-weight-bold"},[_vm._v("Your order is out of delivery and reaching at you soon.\n                ")]),_c('q-btn',{staticClass:"track-btn text-red",attrs:{"unelevated":"","no-caps":"","flat":"","color":"transparent"},on:{"click":function($event){return _vm.openTrackingURL(_vm.orderDetail.trackingUrl)}}},[_vm._v("Track Your Order "),_c('q-icon',{attrs:{"name":"map","color":"red"}})],1)],1)]]:(
              _vm.orderDetail.customerDetailsPickupInfo &&
              _vm.orderDetail.codeName !== 'sd'
            )?[_c('li',[_c('span',[_vm._v("Full Name")]),_vm._v("\n              "+_vm._s(_vm.orderDetail.customerDetailsPickupInfo.customerName)+"\n            ")]),_c('li',[_c('span',[_vm._v("Confirmation Code")]),_c('strong',{domProps:{"innerHTML":_vm._s(
                  _vm.orderDetail.customerDetailsPickupInfo.confirmationCode
                )}})]),(
                _vm.orderDetail.isAgeRestricted &&
                _vm.businessConfigurations.isStoreAgeRestricted &&
                _vm.businessConfigurations.enableAgeRestrictedFulfillment &&
                ((_vm.orderDetail.codeName == 'sp' &&
                  _vm.isOrderStatusReached('Ready_For_Pickup')) ||
                  (_vm.orderDetail.codeName == 'scd' &&
                    _vm.orderDetail.deliveryMethod == 0 &&
                    _vm.isOrderStatusReached('Ready_for_Delivery')))
              )?_c('li',{staticClass:"verification-instructions"},[_c('span',{staticClass:"text-red text-justify"},[_vm._v("\n                The person fulfilling your order will ask for the\n                "),_c('strong',{directives:[{name:"show",rawName:"v-show",value:(_vm.businessConfigurations.allowVerificationByDOB),expression:"businessConfigurations.allowVerificationByDOB"}]},[_vm._v("\n                  Date of Birth\n                ")]),_c('strong',{directives:[{name:"show",rawName:"v-show",value:(_vm.businessConfigurations.allowVerificationByLicense),expression:"businessConfigurations.allowVerificationByLicense"}]},[(_vm.businessConfigurations.allowVerificationByDOB)?[_vm._v("\n                    /\n                  ")]:_vm._e(),_vm._v("\n                  Driver's License\n                ")],2),_c('strong',{directives:[{name:"show",rawName:"v-show",value:(_vm.businessConfigurations.allowVerificationByQR),expression:"businessConfigurations.allowVerificationByQR"}]},[(
                      _vm.businessConfigurations.allowVerificationByDOB ||
                      _vm.businessConfigurations.allowVerificationByLicense
                    )?[_vm._v("\n                    /\n                  ")]:_vm._e(),_vm._v("\n                  Age-Verified QR Code\n                ")],2),_vm._v("\n                to verify the age of the person picking up your order, as the\n                order contains\n                "),_c('strong',[_vm._v("age-restricted")]),_vm._v(" products.\n              ")])]):_vm._e()]:_vm._e()],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }