import { render, staticRenderFns } from "./OrderSummary.vue?vue&type=template&id=29509784"
import script from "./OrderSummary.vue?vue&type=script&lang=js"
export * from "./OrderSummary.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QSeparator,QTooltip});
