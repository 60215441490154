<template>
  <div class="col-12 col-md-12">
    <div class="row">
      <div
        class="col-12 col-md-12 orderSingleLabel"
        v-show="
          orderDetail.trackOrderLineItems &&
          orderDetail.trackOrderLineItems.length
        "
      >
        {{ orderDetail.orderCompleted ? setOrderListTitle : 'Items on Order' }}
      </div>
      <div class="col-12 col-md-12">
        <!-- New UI for Order -->
        <div class="cart-section">
          <div
            class="cart-order"
            v-for="item of orderDetail.trackOrderLineItems"
            :key="item.orderLineItemID"
          >
            <div
              :class="[
                'cart-product sd-spacing q-ml-none',
                item.linkedProducts && item.linkedProducts.length > 0
                  ? 'substitution-product'
                  : item.allowSubstitute
                  ? 'substitution-product'
                  : '',
                item.isFulfilled && item.qty === 0
                  ? 'product-not-available'
                  : '',
              ]"
            >
              <div class="cart-produt-data">
                <div class="produt-data flex items-start">
                  <div
                    class="product-image"
                    :class="{
                      blacklistedproduct: item.isBlacklisted,
                    }"
                  >
                    <box-image
                      :link="{
                        name: 'Detail',
                        params: { seoName: item.seoName },
                      }"
                      :imageSrc="item.image"
                      :width="isMobile ? '80px' : '86px'"
                    />
                  </div>

                  <div class="co-product-details">
                    <div class="product--title">
                      <h3
                        class="ellipsis"
                        :class="{
                          blacklistedproduct: item.isBlacklisted,
                        }"
                      >
                        <router-link
                          :to="{
                            name: 'Detail',
                            params: { seoName: item.seoName },
                          }"
                        >
                          {{ item.productName }}
                        </router-link>
                      </h3>

                      <div class="cart--labels q-mt-sm">
                        <span
                          class="label-success q-mr-xs"
                          v-if="item.isAdded"
                          v-html="`Just Added`"
                        />
                        <span
                          class="label-primary q-mr-xs"
                          v-else-if="item.isUpdated"
                          v-html="`Updated`"
                        />
                        <span v-show="item.allowSubstitute" class="allowed">
                          Substitution Allowed
                          <SvgIcon icon="info-outline" class="q-ml-sm" />
                        </span>
                        <span
                          v-if="item.isBlacklisted"
                          class="text-negative text-body2 block q-mt-xs"
                        >
                          <q-icon name="info"></q-icon> Product No Longer on
                          Site
                        </span>
                      </div>

                      <div
                        class="cart--instruction text-body2 word-break-word q-mt-sm"
                        v-if="item.instruction"
                      >
                        <strong class="text-grey-6">Item instruction:</strong>
                        <br />
                        {{ item.instruction || '(not set)' }}
                      </div>

                      <div class="cart--quantity q-mt-sm"
                        >{{ item.qty }} x
                        <span v-html="$options.filters.currency(item.price)"
                      /></div>
                    </div>

                    <div class="price">
                      <span
                        class="original-price"
                        v-html="$options.filters.currency(item.totalPrice)"
                        v-if="item.totalPrice != item.finalPrice"
                      />
                      <span
                        :class="
                          item.totalPrice != item.finalPrice
                            ? 'discount-price'
                            : ''
                        "
                        v-html="$options.filters.currency(item.finalPrice)"
                      />
                    </div>

                    <div
                      class="product-not-available-label text-negative text-body2"
                      v-if="item.isFulfilled && item.qty === 0"
                    >
                      <span>Product Not Available</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Linked Item -->
              <div
                class="substitution-product--block"
                v-if="item.linkedProducts && item.linkedProducts.length > 0"
              >
                <div
                  class="substitution-product produt-data flex items-center"
                  v-for="(linkedProduct, index) in item.linkedProducts"
                  :key="`${linkedProduct.orderLineItemID}${index}`"
                  :class="[
                    linkedProduct.isFulfilled &&
                    linkedProduct.qty === 0 &&
                    item.qty > 0
                      ? 'product-not-available'
                      : '',
                    {
                      blacklistedproduct: linkedProduct.isBlacklisted,
                    },
                  ]"
                >
                  <div class="product-image">
                    <q-avatar square>
                      <box-image
                        :link="
                          linkedProduct.isSellable
                            ? {
                                name: 'Detail',
                                params: { seoName: linkedProduct.seoName },
                              }
                            : ''
                        "
                        :imageSrc="linkedProduct.image"
                        width="40px"
                      />
                    </q-avatar>
                    <q-avatar flat>
                      <SvgIcon icon="linked" />
                    </q-avatar>
                  </div>
                  <div class="co-product-details">
                    <div class="product--title">
                      <h4>
                        <router-link
                          v-if="linkedProduct.isSellable"
                          :to="{
                            name: 'Detail',
                            params: { seoName: linkedProduct.seoName },
                          }"
                          v-html="linkedProduct.productName"
                        />
                        <template v-else>
                          {{ linkedProduct.productName }}
                        </template>
                        <q-tooltip max-width="180px" content-class="bg-dark">
                          {{ linkedProduct.productName }}
                        </q-tooltip>
                      </h4>
                      <div class="cart--labels q-mt-sm">
                        <span
                          v-if="linkedProduct.isBlacklisted"
                          class="text-negative text-body2 block q-mr-sm"
                        >
                          <q-icon name="info"></q-icon> Product No Longer on
                          Site</span
                        >
                        <span
                          class="product-not-available-label text-negative text-body2"
                          v-if="
                            linkedProduct.isFulfilled &&
                            linkedProduct.qty === 0 &&
                            item.qty > 0
                          "
                          >Product Not Available</span
                        >
                      </div>
                    </div>
                    <div class="price">
                      <span
                        class="original-price"
                        v-html="
                          $options.filters.currency(linkedProduct.totalPrice)
                        "
                        v-if="
                          linkedProduct.totalPrice != linkedProduct.finalPrice
                        "
                      />
                      <span
                        :class="
                          linkedProduct.totalPrice != linkedProduct.finalPrice
                            ? 'discount-price'
                            : ''
                        "
                        v-html="
                          $options.filters.currency(
                            linkedProduct.finalPrice || linkedProduct.totalPrice
                          )
                        "
                      />
                    </div>
                    <div class="flex product-edit--options">
                      <div class="product-quantity">
                        <span
                          v-html="
                            `${linkedProduct.qty} X ${$options.filters.currency(
                              linkedProduct.price
                            )}`
                          "
                      /></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Linked Item -->
            </div>
          </div>
        </div>
        <!-- New UI for Order -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ShopedItems',
  props: {
    orderDetail: {
      type: Object,
      default: new Object(),
    },
  },
  computed: {
    ...mapGetters('cart', ['substitute']),
    setOrderListTitle() {
      let OrderListTitle = ''
      if (
        this.orderDetail.codeName == 'is' ||
        this.orderDetail.codeName == 'cs' ||
        this.orderDetail.codeName == 'sp'
      ) {
        OrderListTitle = 'Picked items'
      } else if (this.orderDetail.codeName == 'sd') {
        OrderListTitle = 'Shipped items'
      } else if (this.orderDetail.codeName == 'scd') {
        OrderListTitle = 'Delivered items'
      }
      return OrderListTitle
    },
  },
}
</script>

<style lang="scss"></style>
