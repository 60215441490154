<template>
  <div class="col-12 col-md-12">
    <div class="orderSummaryInfos">
      <div class="row q-col-gutter-md">
        <div class="col-xs-12 col-sm-6 col-md-4 o-1">
          <ul class="orderSummaryITotal">
            <li>
              <!-- {{ orderDetail.displayName }} Total -->
              Sub Total
              <span v-html="$options.filters.currency(orderDetail.subTotal)" />
            </li>

            <template
              v-if="
                orderDetail.codeName == 'sd' &&
                orderDetail.shippingRate !== null
              "
            >
              <li>
                Shipping Fee
                <span>
                  {{
                    orderDetail.shippingRate == 0
                      ? 'Free'
                      : $options.filters.currency(
                          orderDetail.shippingRate,
                          false,
                          true
                        )
                  }}
                </span>
              </li>
            </template>
            <template v-if="orderDetail?.tipAmount">
              <li>
                Tip
                <span>{{
                  orderDetail?.tipAmount | currency(false, true)
                }}</span>
              </li>
            </template>
            <template
              v-if="
                orderDetail.additionalCharges &&
                orderDetail.additionalCharges.length
              "
            >
              <li
                v-for="additionalCharge of orderDetail.additionalCharges"
                :key="additionalCharge.chargeName"
              >
                {{ additionalCharge.chargeName }}
                <span>{{
                  additionalCharge.chargeAmount | currency(false, true)
                }}</span>
              </li>
            </template>

            <template v-if="orderDetail.discount">
              <li>
                Discount Applied
                <span
                  v-html="$options.filters.currency(orderDetail.discount, true)"
                />
              </li>
            </template>

            <template v-if="orderDetail.buydownAmount">
              <li>
                Other Discount
                <span>- {{ orderDetail.buydownAmount | currency(false) }}</span>
              </li>
            </template>

            <li v-if="orderDetail.appliedLnsDollars">
              Loyalty Dollars Applied
              <span>
                -
                {{ orderDetail.appliedLnsDollars | currency }}
              </span>
            </li>

            <!-- <q-separator class="q-mt-xs" /> -->
            <!-- <li>
              Sub Total
              <span>
                {{
                  (orderDetail.totalOrderAmount -
                    (orderDetail.totalTax ? orderDetail.totalTax : 0) -
                    (orderDetail.appliedLnsDollars
                      ? orderDetail.appliedLnsDollars
                      : 0))
                    | currency
                }}
              </span>
            </li> -->
            <template v-if="orderDetail.otherTax > 0">
              <li>
                Tax
                <span
                  v-html="
                    $options.filters.currency(orderDetail.otherTax, false, true)
                  "
                />
              </li>
            </template>
            <template v-if="orderDetail.occupationalTax > 0">
              <li>
                Occupational Tax
                <span
                  v-html="
                    $options.filters.currency(
                      orderDetail.occupationalTax,
                      false,
                      true
                    )
                  "
                />
              </li>
            </template>
            <template
              v-if="
                orderDetail &&
                orderDetail.codeName &&
                orderDetail.codeName == 'scd' &&
                orderDetail.shippingRate > 0
              "
            >
              <li>
                Delivery Fee
                <span
                  v-html="
                    $options.filters.currency(
                      orderDetail.shippingRate,
                      false,
                      true
                    )
                  "
                />
              </li>
            </template>

            <template
              v-if="
                orderDetail.appliedLnsCoupons &&
                orderDetail.appliedLnsCoupons.length
              "
            >
              <strong
                ><p class="q-mt-sm applied-coupons"
                  >Applied Loyalty Coupon(s)</p
                ></strong
              >
              <q-separator />
              <li
                v-for="appliedLnsCoupon in orderDetail.appliedLnsCoupons"
                :key="appliedLnsCoupon.id"
                style="padding-right: 0px !important"
              >
                <span> - {{ appliedLnsCoupon.amount | currency }} </span>
                <div>
                  {{ appliedCouponName(appliedLnsCoupon.name) }}
                  <q-tooltip max-width="180px" content-class="bg-dark">
                    {{ appliedLnsCoupon.name }}
                  </q-tooltip>
                </div>
              </li>
            </template>

            <template
              v-if="
                orderDetail.appliedDiscountCoupons &&
                orderDetail.appliedDiscountCoupons.length
              "
            >
              <strong
                ><p class="q-mt-sm applied-coupons"
                  >Applied Discount Coupons</p
                ></strong
              >
              <q-separator />
              <li
                v-for="appliedDiscountCoupon in orderDetail.appliedDiscountCoupons"
                :key="appliedDiscountCoupon.id"
                style="padding-right: 0px !important"
              >
                <span> {{ appliedDiscountCoupon.amount | currency }} </span>
                <div>
                  {{ appliedCouponName(appliedDiscountCoupon.couponCode) }}
                  <q-tooltip max-width="180px" content-class="bg-dark">
                    {{ appliedDiscountCoupon.couponCode }}
                  </q-tooltip>
                </div>
              </li>
            </template>
            <!-- <template v-if="orderDetail.appliedLnsDollars">
              <q-separator class="q-mt-xs" />

              <li class="eredeem">
                <div class="text-left q-mb-sm">
                  <span><strong>Loyalty Dollars Applied</strong></span>
                  <strong class="float-right ">
                    <span class="vertical-middle	">
                      -
                      {{ orderDetail.appliedLnsDollars | currency }}
                    </span>
                  </strong>
                </div>
              </li>
            </template> -->

            <q-separator class="q-mt-sm" />

            <li class="total">
              Order Total
              <span
                v-html="$options.filters.currency(orderDetail.finalPayAmount)"
              />
            </li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 o-3">
          <template v-if="orderDetail && orderDetail.deliveryInstruction">
            <h5>Order Instruction</h5>
            <p class="text-justify text-caption word-break-word">
              {{ orderDetail.deliveryInstruction }}
            </p>
          </template>
        </div>
        <div
          class="col-xs-12 col-sm-6 col-md-3 o-2"
          v-if="orderDetail.isPayAtStore || orderDetail.orderPaymentDetail"
        >
          <h5>Payment Method</h5>
          <p v-if="orderDetail.isPayAtStore">
            <SvgIcon size="44px" icon="pay-at-store" class="q-mr-sm" />
            Pay at store
          </p>
          <p v-else-if="orderDetail.orderPaymentDetail">
            <span class="text-weight-bold"> Credit or debit card</span><br />
            <!-- <SvgIcon size="24px" icon="card" class="q-mr-sm" /> -->
            <span :class="`paymentIcon paymentIcon-left`">
              <img
                :src="`images/payment/${
                  getCardType(orderDetail.orderPaymentDetail.cardType).logo
                }`"
                :title="
                  getCardType(orderDetail.orderPaymentDetail.cardType).title
                "
              />
            </span>

            {{ orderDetail.orderPaymentDetail.cardHolderName }}
            <span
              v-if="orderDetail.orderPaymentDetail.isDefault"
              class="default q-ml-sm"
            >
              Default </span
            ><br />
            xxxx-xxxx-xxxx-{{ orderDetail.orderPaymentDetail.cardLast4Digits }}
            <br />
            Expires :
            {{ orderDetail.orderPaymentDetail.cardExpiry }}
          </p>
        </div>
        <!-- <div
          class="col-xs-12 col-sm-6 col-md-4 q-mt-md"
          v-if="orderDetail && orderDetail.deliveryInstruction"
        >
          <h5>Delivery Instruction</h5>
          <p class="text-justify text-caption">
            {{ orderDetail.deliveryInstruction }}
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { appliedCouponName, getCardType } from 'src/utils'
export default {
  name: 'OrderSummary',
  props: {
    orderDetail: {
      type: Object,
      default: new Object(),
    },
  },
  methods: {
    appliedCouponName: appliedCouponName,
    getCardType: getCardType,
  },
}
</script>
